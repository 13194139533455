import { render, staticRenderFns } from "./darkxiyouApplet.vue?vue&type=template&id=7ed554f4&scoped=true"
import script from "./darkxiyouApplet.vue?vue&type=script&lang=js"
export * from "./darkxiyouApplet.vue?vue&type=script&lang=js"
import style0 from "./darkxiyouApplet.vue?vue&type=style&index=0&id=7ed554f4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed554f4",
  null
  
)

export default component.exports